<!--选择外部联系人/选择好友-->
<template>
  <div v-if="show" class="contacts-friend">
    <Header title="选择联系人" @onClose="onClose" />
    <div class="search-input">
      <van-field
        :class="formData.param ? 'search' : ''"
        v-model="formData.param"
        placeholder="请输入姓名/手机号"
      >
        <template #right-icon>
          <van-icon
            :name="require('../../assets/imgs/search_img.png')"
            @click="handleSearch"
          />
        </template>
      </van-field>
      <div v-if="formData.param" class="cell-cancel">
        <span @click="handleCancelSearch">取消</span>
      </div>
    </div>
    <div v-if="myFriendList.length">
      <van-radio-group v-model="radioFirendId">
        <div v-for="(item, index) in myFriendList" :key="index">
          <div class="firend-letter">{{ item.initials }}</div>
          <!-- <van-radio name="1">单选框 1</van-radio>
        <van-radio name="2">单选框 2</van-radio> -->
          <div
            v-for="(item1, index1) in item.children"
            :key="index1"
            class="radio-box"
          >
            <div class="left">
              <div class="item-radio">
                <van-radio
                  :name="item1.id"
                  @click="handleRadioChange(item1)"
                ></van-radio>
              </div>
              <div class="head-img">
                <img
                  :src="
                    item1.headImg || require('@/assets/imgs/default_img.png')
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="right">
              <div class="name">
                {{ item1.name }}
                <span v-if="item1.identity !== '个人身份'">
                  <span style="color:#434751;font-size:10px">•</span>
                  <span>{{ item1.identity || '' }}</span>
                </span>
              </div>
              <div class="phone">{{ item1.telephone || item1.email }}</div>
            </div>
          </div>
        </div>
      </van-radio-group>
      <div v-if="!formData.fristLetter && !formData.param" class="letter-sort">
        <p
          v-for="(item, index) in letterList"
          :key="index"
          @click="handleLetterSearch(item)"
        >
          {{ item.toUpperCase() }}
        </p>
      </div>
    </div>
    <div class="empty" v-else>
      <img src="@/assets/imgs/插画@2x.png" alt="" />
      <span>无此联系人</span>
    </div>
    <div v-if="radioFirendId" class="button-box">
      <div class="button-all" @click="seeFirendShow = true">查看已选(1)</div>
      <div class="button-confirm">
        <van-button type="info" @click="handleConfirm">确定</van-button>
      </div>
    </div>
    <SeeFirendPopup
      :seeFirendShow="seeFirendShow"
      :peopleList="rolePeopleList"
      @onClose="seeFirendShow = false"
    />
  </div>
</template>
<script>
  // 个人身份选 外部个人 1个人身份
  // 个人身份选 外部企业 2企业身份

  // 企业身份选 外部联系人 2企业身份
  // 企业身份选 外部个人(企业架构 外部联系人) 个人身份 type 不传
  import { mapState, mapGetters } from 'vuex'
  import Api from '@/api/businessRole'
  import { hasAuth } from '@/utils/hasAuth'
  import Header from './components/header.vue'
  import SeeFirendPopup from './components/seeFirendPopup.vue'

  const nameLetter = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '#',
  ]

  export default {
    props: {
      type: {
        // 1个人身份 2企业身份
        type: Number,
        default: () => undefined,
      },
      show: {
        type: Boolean,
        default: () => false,
      },
    },
    components: {
      SeeFirendPopup,
      Header,
    },

    data() {
      return {
        selectText: '',
        radioFirendId: '',
        seeFirendShow: false,
        search: false,
        letterList: [],
        formData: {
          fristLetter: '',
          pageNum: 1,
          pageSize: 9999,
          param: '',
          // 0-获取个人身份 1-获取企业身份 不传-都获取
          type: undefined,
        },
        total: 0,
        rowsList: [],
        myFriendList: [],
        rolePeopleList: [],
        depFriendValue: {},
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
      /**
       * 只展示企业架构
       * 1. 选择抄送人
       * 2. 无外部联系人权限
       */
      hiddenOuterList() {
        return !hasAuth(['addressBook_externalContacts'])
      },
    },
    async mounted() {
      this.initType()
      if (!this.hiddenOuterList) {
        await this.getCurrentDepartmentUserFristLetter()
        await this.getFriendList()
      }
    },
    methods: {
      // 根据情况设定type，获取不同身份的外部联系人
      initType() {
        const { type } = this
        // 1.个人身份下 参与方为外部个人 被选方身份不限
        if (type === 1) {
          this.formData.type = 0
        }
        // 2.个人身份下 参与方为外部企业 仅支持选择非个人身份好友(获取企业身份)
        if (type === 2) {
          this.formData.type = 1
        }
      },
      // 获取好友列表
      getFriendList() {
        this.myFriendList = []
        return Api.getExternalContacts(this.formData).then(res => {
          if (res) {
            this.rowsList = res.rowsList
            this.total = res.total
            this.onCalssName()
          }
        })
      },
      // 按首字母分类
      onCalssName() {
        const letter = []
        nameLetter.forEach(item => {
          const data = this.rowsList.filter((item1, index) => {
            // eslint-disable-next-line eqeqeq
            return item == item1.fristLetter.toUpperCase()
          })
          const list = this.letterList.filter((item1, index) => {
            // eslint-disable-next-line eqeqeq
            return item == item1.toUpperCase()
          })
          if (data.length) {
            this.myFriendList.push({
              initials: data[0].fristLetter.toUpperCase(),
              children: data,
            })
          }
          if (list.length) {
            letter.push(list[0])
          }
        })
        this.letterList = letter
      },
      // 获取当前部门所有用户首字母
      getCurrentDepartmentUserFristLetter() {
        return Api.getCurrentDepartmentUserFristLetter({
          type: this.formData.type,
        }).then(res => {
          this.letterList = res
        })
      },
      // 搜索
      handleSearch() {
        this.search = true
        this.formData.fristLetter = ''
        this.getFriendList()
      },
      handleCancelSearch() {
        this.formData.param = ''
        this.formData.fristLetter = ''
        this.search = false
        this.getFriendList()
      },
      handleLetterSearch(val) {
        this.formData.fristLetter = val
        this.getFriendList()
      },
      // radio事件选择好友 val 部门下的人
      handleRadioChange(val) {
        this.rolePeopleList = []
        this.depFriendValue = val
        this.rolePeopleList.push(val)
      },
      handleConfirm() {
        this.$emit('roleCallback', this.depFriendValue)
        this.$emit('onConfirmClose')
      },
      onClose() {
        this.$emit('onClose')
      },
    },
  }
</script>
<style lang="less" scoped>
  .cur-text {
    font-size: 12px;
    font-weight: 400;
    color: #8a9ab4;
  }
  .cur-padding {
    padding: 0 16px;
  }
  .contacts-friend {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 999999;
    overflow-y: scroll;
    overflow-x: hidden;
    .search-input {
      display: flex;
      align-items: center;
      .search {
        padding-right: 0;
      }
      /deep/.van-cell__value {
        height: 32px;
        line-height: 32px;
        background: #f4f5f6;
        border-radius: 16px;
        &:extend(.cur-padding);
        .van-field__right-icon {
          img {
            margin-bottom: 3px;
          }
        }
      }
      .cell-cancel {
        width: 66px;
        height: 32px;
        font-size: 16px;
        line-height: 32px;
        padding-right: 16px;
        text-align: right;
      }
    }
    .firend-letter {
      width: 100%;
      height: 18px;
      background: #f4f5f6;
      line-height: 18px;
      &:extend(.cur-text);
      &:extend(.cur-padding);
    }
    .van-radio-group {
      padding: 0 16px;
    }
    .radio-box {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 16px 0;
      box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
      .left {
        display: flex;
        align-items: center;
        .head-img {
          margin-left: 16px;
          img {
            width: 44px;
            height: 44px;
            border-radius: 22px;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 14px;
        }
        .phone {
          font-size: 14px;
          color: #677283;
          margin-top: 3px;
        }
      }
    }
    .empty {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 240px;
        height: 172px;
        margin-top: 50px;
      }
      span {
        font-size: 14px;
        color: #c1c4cd;
      }
    }
    .button-box {
      width: 100%;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      bottom: 0;
      &:extend(.cur-padding);
      .button-all {
        font-size: 15px;
        color: #1676ff;
      }
      .button-confirm {
        .van-button {
          width: 136px;
          height: 46px;
          background: #1676ff;
          border-radius: 25px;
          font-size: 18px;
        }
      }
    }
    .letter-sort {
      width: 20px;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: 0;
      color: #677283;
      margin-top: 165px;
    }
  }
</style>
